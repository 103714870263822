<template>
  <div class="view pa24">
    <el-form
      :model="ruleForm"
      :rules="rules"
      ref="ruleForm"
      label-position="top"
      class="demo-ruleForm"
    >
      <p class="fs8 fwbold">支付设置</p>
      <el-tabs
        v-model="ruleForm.payType"
        @tab-click="switchPayType"
        class="payType"
      >
        <el-tab-pane
          :label="
            '微信支付配置' + (detailsData.payType === 0 ? '(已启用)' : '')
          "
          name="0"
        ></el-tab-pane>
        <el-tab-pane
          :label="
            '河马支付配置' + (detailsData.payType === 1 ? '(已启用)' : '')
          "
          name="1"
        ></el-tab-pane>
      </el-tabs>
      <div class="ml20 mt10" v-if="ruleForm.payType === '0'">
        <el-form-item label="商户id" prop="merchantId">
          <el-input
            :placeholder="isSucceed('name')"
            v-model="ruleForm.merchantId"
            style="width: 300px"
          ></el-input>
          <p>
            商户id（
            <a
              style="color: #20aee3"
              target="_blank"
              href="https://pay.weixin.qq.com"
              >pay.weixin.qq.com</a
            >
            ）进入【账户中心】--【商户信息】查看
          </p>
        </el-form-item>
        <el-form-item label="商户秘钥（APIv2）" prop="key">
          <el-input
            :placeholder="isSucceed('Id')"
            v-model="ruleForm.key"
            style="width: 300px"
          ></el-input>
          <p>
            商户秘钥（
            <a
              style="color: #20aee3"
              target="_blank"
              href="https://pay.weixin.qq.com"
              >pay.weixin.qq.com</a
            >
            ）进入【账户中心】--【API安全】--【APIv2设置】查看
          </p>
        </el-form-item>
        <el-form-item label="支付证书（API证书）" prop="certData">
          <el-col :lg="24">
            <el-upload
              class="upload-demo"
              :action="uploadUrl"
              :before-upload="beforeUploadP12"
              :before-remove="beforeRemoveP12"
              :limit="1"
              :file-list="p12FileList"
            >
              <el-button size="small" type="primary">点击上传</el-button>
              <div slot="tip" class="el-upload__tip">
                支付证书（
                <a
                  style="color: #20aee3"
                  target="_blank"
                  href="https://pay.weixin.qq.com"
                  >pay.weixin.qq.com</a
                >
                ）进入【账户中心】--【API安全】--【管理证书】查看，证书文件格式p12
              </div>
            </el-upload>
          </el-col>
        </el-form-item>
      </div>
      <div class="ml20 mt10" v-if="ruleForm.payType === '1'">
        <el-form-item label="商户id" prop="merchantId">
          <el-input
            :placeholder="isSucceed('name')"
            v-model="ruleForm.merchantId"
            style="width: 300px"
          ></el-input>
          <p>
            商户id（
            <a
              style="color: #20aee3"
              target="_blank"
              href="https://hmpay.sandpay.com.cn/merchant/login"
              >hmpay.sandpay.com.cn/merchant/login</a
            >
            ）进入【商户后台】--【账户信息】--【河马付商户号】查看
          </p>
        </el-form-item>
        <el-form-item label="平台公钥" prop="publicKey">
          <el-input
            :placeholder="isSucceed('publicKey')"
            v-model="ruleForm.publicKey"
            style="width: 300px"
          ></el-input>
          <p>
            平台公钥（
            <a
              style="color: #20aee3"
              target="_blank"
              href="https://hmpay.sandpay.com.cn/merchant/login"
              >hmpay.sandpay.com.cn/merchant/login</a
            >
            ）进入【商户后台】--【账户信息】--【平台公钥】查看
          </p>
        </el-form-item>
        <el-form-item label="商户私钥" prop="privateKey">
          <el-col :lg="24">
            <el-input
              :placeholder="isSucceed('privateKey')"
              v-model="ruleForm.privateKey"
              style="width: 300px"
            ></el-input>
            <p>
              商户私钥（
              <a
                style="color: #20aee3"
                target="_blank"
                href="https://hmpay.sandpay.com.cn/merchant/login"
                >hmpay.sandpay.com.cn/merchant/login</a
              >
              ）进入【商户后台】--【账户信息】--【商户RSA公钥】--【使用RSA工具】生成私钥
            </p>
          </el-col>
        </el-form-item>
      </div>
      <p class="fs8 fwbold mb10">提现设置</p>
      <div class="ml20">
        <el-form-item label="提现秘钥（APIv3）" prop="apiV3Key">
          <el-input
            :placeholder="isSucceed('Id')"
            v-model="ruleForm.apiV3Key"
            style="width: 300px"
          ></el-input>
          <p>
            提现秘钥（
            <a
              style="color: #20aee3"
              target="_blank"
              href="https://pay.weixin.qq.com"
              >pay.weixin.qq.com</a
            >
            ）进入【账户中心】--【API安全】--【APIv3设置】查看
          </p>
        </el-form-item>
        <el-form-item label="证书序列号" prop="serialNo">
          <el-input
            :placeholder="isSucceed('serialNo')"
            v-model="ruleForm.serialNo"
            style="width: 400px"
          ></el-input>
          <p>
            证书序列号，要对应上传证书的序列号（
            <a
              style="color: #20aee3"
              target="_blank"
              href="https://pay.weixin.qq.com"
              >pay.weixin.qq.com</a
            >
            ）进入【账户中心】--【API安全】--【管理证书】查看
          </p>
        </el-form-item>
        <el-form-item label="私钥证书" prop="pemKey">
          <el-upload
            class="upload-demo"
            :action="uploadUrl"
            :before-upload="beforeUploadPem"
            :before-remove="beforeRemovePem"
            :limit="1"
            :file-list="pemFileList"
          >
            <el-button size="small" type="primary">点击上传</el-button>
            <div slot="tip" class="el-upload__tip">
              私钥证书（
              <a
                style="color: #20aee3"
                target="_blank"
                href="https://pay.weixin.qq.com"
                >pay.weixin.qq.com</a
              >
              ）进入【账户中心】--【API安全】--【管理证书】查看，证书文件格式pem
            </div>
          </el-upload>
        </el-form-item>
      </div>
      <el-form-item>
        <el-col class="mt20" :lg="24">
          <el-button type="primary" @click="submitPayInfo">{{
            btnName
          }}</el-button>
          <el-button
            type="primary"
            @click="resetAuthorization"
            v-if="detailsData.merchantId"
            >重置授权</el-button
          >
        </el-col>
      </el-form-item>
    </el-form>
  </div>
</template>
  
<script>
import { componentloginpage } from "@/api/authorization";
import { submitPayInfo } from "@/api/wechat";
import { Message } from "element-ui";

export default {
  props: {
    accountMap: {
      default: "",
    },
    accountType: {
      default: 4,
    },
  },
  data() {
    return {
      uploadUrl: this.$store.state.uploadingUrl,
      //文件数组
      p12FileList: [],
      pemFileList: [],
      ruleForm: {
        payType: "0",
        merchantId: "",
        key: "",
        certData: "",
        apiV3Key: "",
        pemKey: "",
        serialNo: "",
        publicKey: "",
        privateKey: "",
      },
      rules: {
        merchantId: [
          { required: true, message: "请输入商户ID", trigger: "blur" },
        ],
        key: [{ required: true, message: "请输入商户密钥", trigger: "blur" }],
        publicKey: [
          { required: true, message: "请输入平台公钥", trigger: "blur" },
        ],
        privateKey: [
          { required: true, message: "请输入商户私钥", trigger: "blur" },
        ],
        // apiV3Key: [
        //     { required: true, message: "请输入提现密钥", trigger: "blur" },
        // ],
        // serialNo: [
        //     { required: true, message: "请输入证书序列号", trigger: "blur" },
        // ],
        certData: [{ required: true, message: "请上传支付证书" }],
      },
      idMap: {
        1: "userApp",
        2: "crmApp",
        3: "pcData",
        4: "payInfo",
      },
      detailsData: {},
      btnName: "提 交",
    };
  },
  created() {
    this.getInitData();
  },
  computed: {
    isSucceed() {
      return (type) => {
        if (this.detailsData.payType == this.ruleForm.payType) {
          let str = type === "name" ? "请上传商户ID" : "请上传密钥";
          if (type === "name") {
            str = this.detailsData.merchantId
              ? this.detailsData.merchantId
              : "请上传商户ID";
          } else if (type === "serialNo") {
            str = this.detailsData.serialNo
              ? this.detailsData.serialNo
              : "请输入序列号";
          } else {
            str = this.detailsData.merchantId
              ? "密钥已上传，不会显示密钥信息"
              : "请上传密钥";
          }
          return str;
        } else {
          let str = type === "name" ? "请上传商户ID" : "请上传密钥";
          if (type === "name") {
            str = "请上传商户ID";
          } else if (type === "serialNo") {
            str = "请输入序列号";
          } else {
            str = "请上传密钥";
          }
          return str;
        }
      };
    },
  },
  watch: {
    accountMap: {
      handler() {
        this.ruleForm = {
          payType: this.ruleForm.payType,
          merchantId: "",
          key: "",
          certData: "",
          apiV3Key: "",
          pemKey: "",
          serialNo: "",
          publicKey: "",
          privateKey: "",
        };
        this.getInitData();
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    switchPayType(tab, event) {
      this.ruleForm = {
        payType: this.ruleForm.payType,
        merchantId: "",
        key: "",
        certData: "",
        apiV3Key: "",
        pemKey: "",
        serialNo: "",
        publicKey: "",
        privateKey: "",
      };
      this.getInitData();
      this.ruleForm.payType = tab.index;
    },
    getInitData() {
      for (var i in this.accountMap) {
        if (this.accountMap[i].accountType == this.accountType) {
          if (this.accountMap[i].state == 1) {
            this.detailsData = this.accountMap[i];
            this.ruleForm.payType = String(this.accountMap[i].payType)
              ? String(this.accountMap[i].payType)
              : "0";
            this.rules = {
              merchantId: [],
            };
            this.btnName = "修 改";
          }
          break;
        }
      }
    },
    beforeUploadPem(file) {
      if (file.name.indexOf(".pem") != -1) {
        let reader = new FileReader();
        reader.readAsText(file, "UTF-8");
        reader.onload = (e) => {
          let text = e.target.result;
          this.ruleForm.pemKey = text;
        };
      } else {
        this.$message.error("格式不正确，请上传正确的证书格式文件");
        return false;
      }
    },
    beforeRemovePem() {
      this.ruleForm.pemKey = "";
    },
    /**@method 支付证书上传回调 */
    beforeUploadP12(file) {
      if (file.type === "application/x-pkcs12") {
        const that = this;
        var reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = function (e) {
          let base64Data = e.target.result;
          base64Data = base64Data.substring(base64Data.indexOf(",") + 1);
          that.ruleForm.certData = base64Data;
        };
      } else {
        this.$message.error("格式不正确，请上传正确的证书格式文件");
        return false;
      }
    },
    /**@method 删除支付证书 */
    beforeRemoveP12() {
      this.ruleForm.certData = "";
    },
    /**@method 提交上传 */
    submitPayInfo() {
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          let data = {
            ...this.ruleForm,
            accountId: this.detailsData.accountId,
            accountType: this.accountType, //crm支付
          };
          if (this.ruleForm.payType === "0") {
            if (!this.ruleForm.certData && !this.detailsData.accountId) {
              this.$message.error("请上传支付证书");
              return false;
            }
            //微信支付配置
            //删除提交参数
            // 平台公钥（APIv2）
            // 商户私钥（API证书）
            delete data.publicKey;
            delete data.privateKey;
          } else {
            //第三方支付配置
            //删除提交参数
            // 商户秘钥（APIv2）
            // 支付证书（API证书）
            delete data.key;
            delete data.certData;
          }
          submitPayInfo(data)
            .then((res) => {
              Message({ message: "上传成功", type: "success" });
              this.$emit("submitPay");
            })
            .catch((err) => {
              if (err.code === 201) {
                Message({ message: err.message, type: "error" });
              }
            });
        }
      });
    },
    resetAuthorization() {
      let accountId = "";
      for (var i in this.accountMap) {
        if (this.accountMap[i].accountType == this.accountType) {
          accountId = this.accountMap[i].accountId;
        }
      }
      if (!accountId) {
        this.$message.error("还未上传支付证书");
        return;
      }
      this.$emit("resetAuthorization", accountId);
    },
  },
};
</script>
  
<style lang="scss" scoped>
/deep/.payType .el-tabs__item {
  width: auto !important;
  padding: 0 20px !important;
}
/deep/.el-upload--text {
  background-color: #fff;
  border: 0px dashed #d9d9d9;
  border-radius: 6px;
  box-sizing: border-box;
  width: 84px;
  height: 34px;
  text-align: center;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
</style>
  