<template>
  <commit-pay :accountType="accountType" :accountMap="accountMap" @submitPay="submitPay"></commit-pay>
</template>

<script>
import commitPay from "./commitPay"

export default {
  components: {
    commitPay
  },
  props: {
    accountMap: {
      default: ''
    }
  },
  data() {
    return {
      accountType: 6,
    };
  },
  created() {

  },
  methods:{
    submitPay(){
      this.$emit("submitPay")
    }
  }
};
</script>

<style lang="scss" scoped></style>
