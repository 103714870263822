<template>
  <div>
    <el-tabs v-model="activeName" @tab-click="handleClick">
      <el-tab-pane label="名片端支付" name="userpay">
        <userpay :accountMap="accountMap" @resetAuthorization="resetAuthorization" @submitPay="getAppListInfo()" v-if="activeName == 'userpay'" />
      </el-tab-pane>
      <el-tab-pane label="crm端支付" name="crmpay">
        <crmpay :accountMap="accountMap" @resetAuthorization="resetAuthorization" @submitPay="getAppListInfo()" v-if="activeName == 'crmpay'" />
      </el-tab-pane>
      <el-tab-pane label="园区端支付" name="yqpay">
        <yuanqvpay :accountMap="accountMap" @resetAuthorization="resetAuthorization" @submitPay="getAppListInfo()" v-if="activeName == 'yqpay'" />
      </el-tab-pane>
      <el-tab-pane label="社团端支付" name="stpay">
        <shetuanpay :accountMap="accountMap" @resetAuthorization="resetAuthorization" @submitPay="getAppListInfo()" v-if="activeName == 'stpay'" />
      </el-tab-pane>
      <el-tab-pane label="政务端支付" name="zwpay">
        <zhengwupay :accountMap="accountMap" @resetAuthorization="resetAuthorization" @submitPay="getAppListInfo()" v-if="activeName == 'zwpay'" />
      </el-tab-pane>
      <!-- <el-tab-pane label="基础设置"></el-tab-pane> -->
      <el-tab-pane label="登录设置" name="fifth" v-if="userInfo.main">
        <authorizationSwitch :accountMap="accountMap" @resetAuthorization="resetAuthorization"
          v-if="activeName == 'fifth'" />
      </el-tab-pane>
    </el-tabs>
    <el-dialog :close-on-click-modal="false" :modal-append-to-body="false" title="是否重置授权信息" width="30%" :center="true" :visible="dialogTableVisible"
      @close="dialogClose">
      <p class="fs8 text-align cA1">
        重置以后需要重新配置小程序信息
      </p>
      <div class="mt40 flex-c-c ">
        <el-button type="primary" plain @click="cancel">
          取消
        </el-button>
        <el-button type="primary" @click="onConfirmShop">
          确认
        </el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import userpay from './components/userpay';
import crmpay from './components/crmpay';
import yuanqvpay from './components/yuanqvpay';
import shetuanpay from './components/shetuanpay';
import zhengwupay from './components/zhengwupay';

import authorizationSwitch from './components/authorizationSwitch';
import {
  getCompanyAccountList,
  unbindCompanyAccount
} from "@/api/authorization"
export default {
  data() {
    return {
      activeName: "userpay",
      accountMap: '',
      userInfo: '',
      dialogTableVisible: false,
      accountId: '',
    };
  },
  components: {
    userpay,
    crmpay,
    yuanqvpay,
    shetuanpay,
    zhengwupay,

    authorizationSwitch
  },
  created() {
    this.userInfo = JSON.parse(localStorage.getItem('info'))
    this.getAppListInfo()
  },
  methods: {
    handleClick(tab) {
      const { name } = tab;
      this.activeName = name;
      this.getAppListInfo()
    },
    async getAppListInfo() {
      const result = await getCompanyAccountList({ appid: true })
      //-console.log(result)
      if (result.data) {
        let tmp = {};
        result && result.data.map(val => {
          tmp[val.accountType] = val;
        });
        this.accountMap = tmp;
        //-console.log(this.accountMap)
      }
    },
    dialogClose() {
      this.dialogTableVisible = false
    },
    resetAuthorization(id) {
      this.accountId = id
      this.dialogTableVisible = true
    },
    cancel() {
      this.dialogTableVisible = false
    },
    async onConfirmShop() {
      let data = {
        accountId: this.accountId
      }
      const result = await unbindCompanyAccount(data)
      //-console.log(result)
      if (result.code == 200) {
        this.$message.success('重置成功！')
        this.dialogTableVisible = false
        this.getAppListInfo()
      } else {
        this.$message.error('重置失败，请稍后重试')
      }
    }
  },
};
</script>

<style lang="scss" scoped>
</style>